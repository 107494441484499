import styled from "styled-components";

const Button = styled.button`
  color: white;
  font-size: 20px;
  border-radius: 10px;
  border: none;
  background-color: rgb(33, 121, 115);
  padding: 10px 20px;
  box-shadow: 0 0 2px 0 #0000006e;
  margin: 10px 0 20px 0;
`;

export default Button;
