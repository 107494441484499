import React from "react";

import styled from "styled-components";
import Button from "./Button";

const QuantityButton = styled((props) => <Button {...props} />)`
  border-radius: 50px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  outline: none;
  cursor: pointer;

  padding: 5px 5px;
  margin: 0;
`;

export default QuantityButton;
