import React, { FC, ReactNode, useEffect, useState } from "react";
import styled from "styled-components";
import Link from "next/link";
import ShoppingCart from "./ShoppingCart";
import { clearData, client, getOrCreateCheckout } from "../shopify";
import { CartContext } from "../contexts";
import { TiArrowBack } from "react-icons/ti";
import { SESSION_STORAGE_KEYS } from "../storage";
import { CartItem, LineItemToAdd, LineItemToUpdate } from "../types";
import { SELECTORS } from "../breakpoints";
import Footer from "./Footer";
import Head from "next/head";
import { log } from "../debug";
import LogoSvg from "../svg/logo_berkshire.svg";
import Bugsnag from "@bugsnag/js";

const Header = styled.header`
  position: fixed;
  top: 0;
  left: 0;

  color: white;
  background-color: white;

  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  ${SELECTORS.PHONE} {
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.6) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  ${SELECTORS.DESKTOP} {
    color: black;
    background: none;
  }
`;

const BackLink = styled.a`
  margin: 5px;
  cursor: pointer;
`;

const InvertedHeader = styled((props) => <Header {...props} />)`
  position: absolute;
  top: 0;
  left: 0;

  color: black;

  ${SELECTORS.PHONE} {
    background: linear-gradient(
      180deg,
      rgb(0 0 0 / 60%) 30%,
      rgba(255, 255, 255, 0) 100%
    );
  }
`;

const Navigation = styled.nav`
  width: 100%;
  height: 100%;

  font-size: 36px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

const Page = styled.div`
  position: relative;

  width: 100%;
  height: 100%;
`;

const Content = styled.div`
  position: relative;
  top: 0;
  left: 0;

  background-color: white;
`;

const Main = styled.main``;

const HeaderImage = styled((props) => <LogoSvg {...props} />)`
  position: static;
  top: 0;
  left: 0;

  margin: 5px;

  height: 60px;
  width: 210px;

  border-radius: 3px;
  overflow: hidden;

  user-select: none;
`;

const HeaderImageAnchor = styled.a``;

const Layout: FC<{
  children: ReactNode;
  inverted?: boolean;
  canGoBack?: boolean;
  title: string;
  description: string;
  socialImageUrl: string;
  socialImageAltText: string;
  socialUrl: string;
}> = ({
  children,
  title,
  socialImageAltText,
  socialImageUrl,
  description,
  inverted = false,
  canGoBack = true,
  socialUrl,
}) => {
  const [updateCartSwitch, setUpdateCartSwitch] = useState<boolean>(false);
  const [lineItems, setLineItems] = useState<{ [id: string]: CartItem }>({});
  const [updateShopifyTimeoutId, setUpdateShopifyTimeoutId] = useState<
    ReturnType<typeof setTimeout> | undefined
  >(undefined);
  const [checkoutUrl, setCheckoutUrl] = useState<string | undefined>(undefined);

  const updateCart = () => {
    setUpdateCartSwitch(!updateCartSwitch);
  };

  useEffect(() => {
    const parsedLineItems = JSON.parse(
      localStorage.getItem(SESSION_STORAGE_KEYS.LINE_ITEMS) || "{}"
    );

    const failingObjects = Object.keys(parsedLineItems).filter(
      (key: string) => {
        const lineItem = parsedLineItems[key];

        return !lineItem.minBuyQuantity || !lineItem.unit;
      }
    );

    if (failingObjects.length > 0 && Object.keys(parsedLineItems).length > 0) {
      clearData();
      setLineItems({});
      setCheckoutUrl(undefined);

      Bugsnag.notify(
        new Error("Cleared session without unit and min buy quantity")
      );
    } else {
      setLineItems(parsedLineItems);
      getUpdatesFromShopify(parsedLineItems);
    }
  }, []);

  useEffect(() => {
    const checkoutUrlFromSession = localStorage.getItem(
      SESSION_STORAGE_KEYS.CHECKOUT_URL
    );

    if (!checkoutUrlFromSession) {
      return;
    }

    setCheckoutUrl(checkoutUrlFromSession);
  }, []);

  const getUpdatesFromShopify = async (localLineItems: {
    [id: string]: CartItem;
  }) => {
    const currentCheckoutId = localStorage.getItem(
      SESSION_STORAGE_KEYS.CHECKOUT_ID
    );

    if (!currentCheckoutId) {
      return;
    }

    const checkout = await client.checkout.fetch(currentCheckoutId);

    const updatedLineItems: { [key: string]: CartItem } = {};

    /*
     * If the checkout is completedAt - meaning paid for and ordered.
     *
     * Or if the checkout has expired and does not exist any more on
     * Shopify's side.
     *
     * We then clear all the data and start fresh.
     */
    if (checkout == null || checkout?.completedAt != null) {
      if (checkout != null) {
        log(`Checkout '${checkout.id}' completed - clearing localStorage`);
      } else {
        log(`Checkout expired - clearing localStorage`);
      }

      clearData();

      setLineItems({});
      setCheckoutUrl(undefined);

      return;
    }

    checkout.lineItems.forEach((lineItem: any) => {
      // TODO(@benedicteb,2021-03-15) Fix wrong typings
      // eslint-disable-next-line
      // @ts-ignore
      const variantId = lineItem.variant.id;

      // TODO(@benedicteb, 2021-03-25) Fix typings which mean image helpers arent there
      // eslint-disable-next-line
      // @ts-ignore
      const thumbnailUrl = client.image.helpers.imageForSize(
        // TODO(@benedicteb,2021-03-15) Fix wrong typings
        // eslint-disable-next-line
        // @ts-ignore
        lineItem.variant.image,
        {
          maxWidth: 100,
          maxHeight: 100,
        }
      );

      if (!localLineItems[variantId]) {
        clearData();

        setLineItems({});
        setCheckoutUrl(undefined);

        Bugsnag.notify(
          new Error(
            "Cleared session because unable to find local cart to merge data with"
          )
        );

        return;
      }

      updatedLineItems[variantId] = {
        ...localLineItems[variantId],

        // TODO(@benedicteb,2021-03-25) Fix wrong typings
        // eslint-disable-next-line
        // @ts-ignore
        handle: lineItem.variant.product.handle,
        // TODO(@benedicteb,2021-03-25) Fix wrong typings
        // eslint-disable-next-line
        // @ts-ignore
        price: parseFloat(lineItem.variant.price.amount),
        quantity: lineItem.quantity,
        title: lineItem.title,

        variantId,
        thumbnailUrl,
      };
    });

    localStorage.setItem(
      SESSION_STORAGE_KEYS.LINE_ITEMS,
      JSON.stringify(updatedLineItems)
    );

    setLineItems(updatedLineItems);
  };

  const updateShopify = async () => {
    const parsedLineItems = JSON.parse(
      localStorage.getItem(SESSION_STORAGE_KEYS.LINE_ITEMS) || "{}"
    );

    const checkout = await getOrCreateCheckout();

    // TODO(@benedicteb, 2021-03-11) webUrl is an attribute under lineItem
    // eslint-disable-next-line
    // @ts-ignore
    setCheckoutUrl(checkout.webUrl);

    const lineItemsToAdd: LineItemToAdd[] = [];
    const lineItemsToUpdate: LineItemToUpdate[] = [];
    const lineItemsToRemove: string[] = [];

    Object.keys(parsedLineItems).forEach((variantId) => {
      const item = parsedLineItems[variantId];

      const lineItemToUpdate = checkout.lineItems.find(
        // TODO(@benedicteb,2021-03-15) Fix wrong typings
        // eslint-disable-next-line
        // @ts-ignore
        (lineItem) => lineItem.variant.id === variantId
      );

      const variantAlreadyInCheckout = lineItemToUpdate?.id != undefined;

      const quantityHasChanged =
        lineItemToUpdate?.quantity != undefined &&
        lineItemToUpdate?.quantity != item.quantity;

      if (variantAlreadyInCheckout && !quantityHasChanged) {
        // Nothing to do
      } else if (variantAlreadyInCheckout && quantityHasChanged) {
        lineItemsToUpdate.push({
          id: String(lineItemToUpdate?.id),
          quantity: item.quantity,
        });
      } else {
        lineItemsToAdd.push({
          variantId,
          quantity: item.quantity,
        });
      }
    });

    checkout.lineItems.forEach((lineItem: any) => {
      // TODO(@benedicteb,2021-03-15) Fix wrong typings
      // eslint-disable-next-line
      // @ts-ignore
      const variantId = lineItem.variant.id;

      if (parsedLineItems[variantId] === undefined) {
        lineItemsToRemove.push(String(lineItem.id));
      }
    });

    if (lineItemsToAdd.length > 0) {
      log("ADDING: ", lineItemsToAdd);
      await client.checkout.addLineItems(checkout.id, lineItemsToAdd);
    }

    if (lineItemsToUpdate.length > 0) {
      log("UPDATING: ", lineItemsToUpdate);
      await client.checkout.updateLineItems(checkout.id, lineItemsToUpdate);
    }

    if (lineItemsToRemove.length > 0) {
      log("REMOVING: ", lineItemsToRemove);
      await client.checkout.removeLineItems(checkout.id, lineItemsToRemove);
    }
  };

  const addToCart = (item: CartItem) => {
    const MINIMUM_ALLOWED_QUANTITY = item.minBuyQuantity;

    const updatedLineItems = { ...lineItems };
    const quantityIsIncreased = item.quantity > 0;

    if (updatedLineItems[item.variantId] != undefined) {
      const newQuantity =
        updatedLineItems[item.variantId].quantity + item.quantity;

      if (newQuantity < MINIMUM_ALLOWED_QUANTITY) {
        if (quantityIsIncreased) {
          updatedLineItems[item.variantId] = {
            ...updatedLineItems[item.variantId],

            quantity: MINIMUM_ALLOWED_QUANTITY,
          };
        } else {
          delete updatedLineItems[item.variantId];
        }
      } else {
        updatedLineItems[item.variantId] = {
          ...updatedLineItems[item.variantId],

          quantity: newQuantity,
        };
      }
    } else {
      updatedLineItems[item.variantId] = {
        ...item,
        quantity: MINIMUM_ALLOWED_QUANTITY,
      };
    }

    localStorage.setItem(
      SESSION_STORAGE_KEYS.LINE_ITEMS,
      JSON.stringify(updatedLineItems)
    );

    setLineItems(updatedLineItems);

    if (updateShopifyTimeoutId != undefined) {
      clearTimeout(updateShopifyTimeoutId);
    }

    const newUpdateShopifyTimeoutId = setTimeout(updateShopify, 500);

    setUpdateShopifyTimeoutId(newUpdateShopifyTimeoutId);
  };

  const InvertAwareHeader = inverted ? InvertedHeader : Header;

  return (
    <>
      <Head>
        <title>{title} - Sus i Serken</title>

        <meta name="description" content={description} />
        <meta property="og:title" content={`${title} - Sus i Serken`} />
        <meta name="twitter:card" content="summary_large_image" />

        <meta property="og:description" content={description} />
        <meta property="og:image" content={socialImageUrl} />
        <meta
          property="og:url"
          content={`https://${process.env.NEXT_PUBLIC_DOMAIN}${socialUrl}`}
        />
        <meta name="twitter:image:alt" content={socialImageAltText} />
      </Head>

      <Page>
        <CartContext.Provider
          value={{ checkoutUrl, lineItems, addToCart, updateCart }}
        >
          <Content>
            <Main>{children}</Main>

            <InvertAwareHeader>
              <Navigation>
                {canGoBack ? (
                  <Link href={"/"} passHref>
                    <BackLink>
                      <TiArrowBack />
                    </BackLink>
                  </Link>
                ) : (
                  <Link href={"/"} passHref>
                    <HeaderImageAnchor>
                      <HeaderImage />
                    </HeaderImageAnchor>
                  </Link>
                )}

                <ShoppingCart inverted={inverted} />
              </Navigation>
            </InvertAwareHeader>

            <Footer />
          </Content>
        </CartContext.Provider>
      </Page>
    </>
  );
};

export default Layout;
