import { createContext } from "react";
import { CartItem } from "./types";

type CartContext = {
  lineItems: { [id: string]: CartItem };
  checkoutUrl: string | undefined;
  updateCart: { (): void };
  addToCart: { (item: CartItem): void };
};

const defaultCartContext: CartContext = {
  lineItems: {},
  checkoutUrl: undefined,
  updateCart: () => {
    // TODO(@benedicteb, 2021-03-11) Avoid using empty function
  },
  // TODO(@benedicteb, 2021-03-15) Find way to remove unused arg in default func
  // eslint-disable-next-line
  addToCart: (item) => {
    // TODO(@benedicteb, 2021-03-15) Avoid using empty function
  },
};

const CartContext = createContext(defaultCartContext);

export { CartContext };
