// TODO(@benedicteb, 2021-03-25) Find better type for debug log function
// eslint-disable-next-line
const log = (...messages: any[]): void => {
  const isProduction = process.env.NEXT_PUBLIC_ENVIRONMENT === "production";

  if (!isProduction) {
    console.log(messages);
  }
};

export { log };
