import React, { FC, useState } from "react";
import styled from "styled-components";
import { TiShoppingCart } from "react-icons/ti";
import { CgMathMinus, CgMathPlus } from "react-icons/cg";
import Currency from "./Currency";
import { CartContext } from "../contexts";
import Link from "next/link";
import { CartItem } from "../types";
import QuantityButton from "../styles/QuantityButton";
import Unit from "./Unit";
import { SELECTORS } from "../breakpoints";
import EmptyComponent from "./EmptyComponent";
import QuantityWithUnit from "./QuantityWithUnit";

const CartButton = styled.button`
  border: none;
  font-size: 36px;
  background: none;
  color: white;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  margin: 13px 13px;

  outline: none;

  position: relative;

  ${SELECTORS.DESKTOP} {
    color: black;
  }
`;

const InvertedCartButton = styled((props) => <CartButton {...props} />)`
  color: white;

  margin: 0;
`;

const ColoredCartButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  top: 0;
  right: 0;

  margin: 5px;

  padding: 8px;
  border-radius: 40px;
  border: 2px solid rgb(23 70 67);
  background: rgb(33, 121, 115);

  animation: fadeIn 0.3s ease-in;

  ${SELECTORS.DESKTOP} {
    padding: 10px;
    border-radius: 90px;
    margin: 3px;
  }

  @keyframes fadeIn {
    0% {
      background: rgba(255, 255, 255, 0);
      border-color: rgba(255, 255, 255, 0);
    }

    100% {
      border-color: rgba(23 70 67, 1);
      background: rgba(33, 121, 115, 1);
    }
  }
`;

const StyledCartIcon = styled((props) => <TiShoppingCart {...props} />)`
  &:hover {
    animation: spin 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

const Badge = styled.p`
  background-color: #dc3c3c;
  color: white;
  border-radius: 20px;
  font-size: 14px;
  font-weight: bold;
  font-family: mono;
  padding: 3px 5px;

  min-width: 20px;

  position: absolute;
  top: 0;
  right: 0;

  margin: -4px -4px 0 0;

  ${SELECTORS.DESKTOP} {
    font-size: 0.5em;
    padding: 3px 8px;
    border-radius: 80px;

    margin: -10px -10px 0 0;
  }
`;

const InvertedBadge = styled((props) => <Badge {...props} />)`
  margin: -10px -10px 0 0;

  ${SELECTORS.DESKTOP} {
    margin: -14px -14px 0 0;
  }
`;

const Line = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  width: 100%;
  height: 5rem;

  margin: 0 0 15px 0;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  height: 100%;

  margin: 0 0 0 5px;
`;

const Popover = styled.div`
  position: fixed;
  top: 0;
  right: 0;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  color: black;
  background-color: white;

  padding: 10px;
  margin: 45px 10px 0 0;

  border-radius: 3px 0 3px 3px;
  box-shadow: 0px 0px 2px 0px #000000a8;

  min-width: 18rem;

  z-index: 2;

  ${SELECTORS.DESKTOP} {
    margin-right: 20px;
    margin-top: 50px;
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;

  z-index: 1;

  height: 100%;
  width: 100%;
`;

const Image = styled.img`
  border-radius: 3px;

  box-shadow: 0px 0px 2px 0px #0000006e;

  width: 60px;
`;

const CheckoutButton = styled.a`
  font-size: 20px;

  border-radius: 10px;
  border: none;

  color: white;
  background-color: rgb(33, 121, 115);

  margin: 20px 0 10px 0;
  padding: 10px 40px;

  box-shadow: 0px 0px 2px 0px #0000006e;
`;

const QuantityLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  width: 7rem;
`;

const Details = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  height: 100%;
`;

const Subtotal = styled.p`
  font-weight: bold;
  font-style: italic;

  font-size: 0.7em;
`;

const UnitPrice = styled.p`
  font-size: 0.45em;
  color: gray;
`;

const ProductTitle = styled.h1`
  font-size: 0.6em;
`;

const Quantity = styled.p`
  font-size: 0.6em;
`;

const ItemPrice = styled.p`
  font-size: 0.7em;
  margin: -4px 0 0 0;
`;

const SubtotalLine = styled((props) => <Line {...props} />)`
  height: 1rem;

  padding: 10px 0 0 0;
  border-top: 1px solid #00000047;
`;

const EmptyCartText = styled.p`
  font-size: 0.6em;
`;

const CartContents: FC<{
  checkoutUrl: string | undefined;
  lineItems: { [id: string]: CartItem };
  addToCart: { (item: CartItem): void };
}> = ({ checkoutUrl, lineItems, addToCart }) => (
  <>
    {Object.keys(lineItems).map((variantId, index) => {
      const lineItem = lineItems[variantId];

      const title = lineItem.title;
      const quantity = lineItem.quantity;
      const unitPrice = lineItem.price;
      const thumbnailUrl = lineItem.thumbnailUrl;

      return (
        <Line key={index}>
          <Details>
            <Link href={`/produkter/${lineItem.handle}`}>
              <a>
                <Image src={thumbnailUrl} />
              </a>
            </Link>

            <Column>
              <Link href={`/produkter/${lineItem.handle}`}>
                <a>
                  <ProductTitle>{title}</ProductTitle>
                </a>
              </Link>

              <QuantityLine>
                <QuantityButton
                  onClick={() => {
                    addToCart({
                      variantId: lineItem.variantId,
                      title: lineItem.title,
                      handle: lineItem.handle,
                      thumbnailUrl: lineItem.thumbnailUrl,
                      unit: lineItem.unit,
                      minBuyQuantity: lineItem.minBuyQuantity,
                      quantity: -1,

                      price: unitPrice,
                    });
                  }}
                >
                  <CgMathMinus />
                </QuantityButton>

                <Quantity>
                  {quantity}{" "}
                  <QuantityWithUnit unit={lineItem.unit} quantity={quantity} />
                </Quantity>

                <QuantityButton
                  onClick={() => {
                    addToCart({
                      variantId: lineItem.variantId,
                      title: lineItem.title,
                      handle: lineItem.handle,
                      thumbnailUrl: lineItem.thumbnailUrl,
                      unit: lineItem.unit,
                      minBuyQuantity: lineItem.minBuyQuantity,
                      quantity: +1,

                      price: unitPrice,
                    });
                  }}
                >
                  <CgMathPlus />
                </QuantityButton>
              </QuantityLine>

              <UnitPrice>
                <Currency value={unitPrice} /> <Unit unit={lineItem.unit} />
              </UnitPrice>
            </Column>
          </Details>

          <ItemPrice>
            <Currency value={unitPrice * quantity} />
          </ItemPrice>
        </Line>
      );
    })}

    <SubtotalLine key={99}>
      <Subtotal>Å betale</Subtotal>

      <Subtotal>
        <Currency
          value={Object.keys(lineItems)
            .map(
              (variantId) =>
                lineItems[variantId].price * lineItems[variantId].quantity
            )
            .reduce((a, b) => a + b)}
        />
      </Subtotal>
    </SubtotalLine>

    <CheckoutButton href={checkoutUrl}>Gå til kassen</CheckoutButton>
  </>
);

const ShoppingCart: FC<{ inverted?: boolean }> = ({ inverted = false }) => {
  const [open, setOpen] = useState<boolean>(false);

  const InvertAwareCartButton = inverted ? InvertedCartButton : CartButton;
  const InvertAwareBadge = inverted ? InvertedBadge : Badge;
  const ButtonWrapper = inverted ? ColoredCartButton : EmptyComponent;

  return (
    <CartContext.Consumer>
      {({ addToCart, checkoutUrl, lineItems }) => (
        <>
          <ButtonWrapper>
            <InvertAwareCartButton
              onClick={() => {
                setOpen(!open);
              }}
            >
              <StyledCartIcon />

              {Object.keys(lineItems).length > 0 ? (
                <InvertAwareBadge>
                  {Object.keys(lineItems).length}
                </InvertAwareBadge>
              ) : null}
            </InvertAwareCartButton>
          </ButtonWrapper>

          {open ? (
            <Popover>
              {Object.keys(lineItems).length > 0 ? (
                <CartContents
                  addToCart={addToCart}
                  checkoutUrl={checkoutUrl}
                  lineItems={lineItems}
                />
              ) : (
                <EmptyCartText>Oops! Handlekurven er tom...</EmptyCartText>
              )}
            </Popover>
          ) : null}

          {open ? (
            <Overlay
              onClick={() => {
                setOpen(!open);
              }}
            />
          ) : null}
        </>
      )}
    </CartContext.Consumer>
  );
};

export default ShoppingCart;
