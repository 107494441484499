import Client, { Cart } from "shopify-buy";
import { SESSION_STORAGE_KEYS } from "./storage";

const client = Client.buildClient({
  domain: process.env["NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN"] || "missing-domain",
  storefrontAccessToken:
    process.env["NEXT_PUBLIC_SHOPIFY_STOREFRONT_ACCESS_TOKEN"] ||
    "missing-token",
});

const getOrCreateCheckout = async (): Promise<typeof Cart> => {
  const currentCheckoutId = localStorage.getItem(
    SESSION_STORAGE_KEYS.CHECKOUT_ID
  );

  const checkout = currentCheckoutId
    ? await client.checkout.fetch(currentCheckoutId)
    : await client.checkout.create();

  localStorage.setItem(SESSION_STORAGE_KEYS.CHECKOUT_ID, String(checkout.id));

  // TODO(@benedicteb, 2021-03-11) webUrl is an attribute under lineItem
  // eslint-disable-next-line
  // @ts-ignore
  const checkoutUrl = checkout.webUrl;

  localStorage.setItem(SESSION_STORAGE_KEYS.CHECKOUT_URL, String(checkoutUrl));

  return checkout;
};

const clearData = (): void => {
  localStorage.removeItem(SESSION_STORAGE_KEYS.CHECKOUT_URL);
  localStorage.removeItem(SESSION_STORAGE_KEYS.LINE_ITEMS);
  localStorage.removeItem(SESSION_STORAGE_KEYS.CHECKOUT_ID);
};

export { client, getOrCreateCheckout, clearData };
