import React, { FC } from "react";
import styled from "styled-components";
import { SELECTORS } from "../breakpoints";
import LogoSvg from "../svg/logo_berkshire.svg";

const FooterHeader = styled.div`
  align-self: center;
`;

const StyledLogo = styled((props) => <LogoSvg {...props} />)`
  width: 200px;
  height: 50px;
`;

const FooterRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  margin: 30px 0 0 0;

  ${SELECTORS.PHONE} {
    justify-content: flex-start;
    flex-direction: column;
  }

  ${SELECTORS.DESKTOP} {
    justify-content: space-between;
    width: 100%;
    padding: 0 30px;
  }
`;

const StyledFooter = styled.footer`
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  padding: 20px 15px;

  color: #000000a3;
  min-height: 600px;
  background-color: #2121210a;
  border-top: 1px solid #0000000f;
`;

const Header = styled.h1`
  font-size: 1.5em;
`;

const Paragraph = styled.p`
  font-size: 1.2em;
  margin: 10px 0;
`;

const ExternalLink = styled.a`
  margin: 5px 0;

  text-decoration: underline;

  :hover {
    color: #b7b7b7;
  }
`;

const FooterGroup = styled.div`
  ${SELECTORS.PHONE} {
    margin: 0 0 30px 0;
  }

  ${SELECTORS.DESKTOP} {
    max-width: 400px;
  }
`;

const Footer: FC = () => (
  <StyledFooter>
    <FooterHeader>
      <StyledLogo />
    </FooterHeader>

    <FooterRow>
      <FooterGroup>
        <Header>Angrerett</Header>

        <Paragraph>
          Sus i Serken AS følger selvsagt{" "}
          <ExternalLink
            rel="noopener"
            target="_blank"
            href="https://lovdata.no/dokument/NL/lov/1988-05-13-27"
          >
            Kjøpsloven
          </ExternalLink>{" "}
          og{" "}
          <ExternalLink
            rel="noopener"
            target="_blank"
            href="https://lovdata.no/dokument/NL/lov/2014-06-20-27"
          >
            Angrerettloven
          </ExternalLink>
          . Vi gjør likevel spesielt oppmerksom på Angrerettlovens §22 punkt e:
          «Angreretten gjelder ikke ved levering av varer som er fremstilt etter
          forbrukerens spesifikasjoner, eller som har fått et tydelig personlig
          preg.»
        </Paragraph>

        <Paragraph>
          Vi har personlig og nitid kontroll med våre varer, men om det skulle
          vise seg å være fabrikkfeil på et stoff, refunderer vi selvsagt
          kundens utlegg.
        </Paragraph>
      </FooterGroup>

      <FooterGroup>
        <Header>Sosiale medier</Header>

        <Paragraph>
          <ExternalLink
            rel="noopener"
            target="_blank"
            href="https://www.instagram.com/sus_i_serken/"
          >
            Instagram
          </ExternalLink>
        </Paragraph>

        <Paragraph>
          <ExternalLink
            rel="noopener"
            target="_blank"
            href="https://www.facebook.com/susiserken1"
          >
            Facebook
          </ExternalLink>
        </Paragraph>
      </FooterGroup>

      <FooterGroup>
        <Header>Søre Brekka - Sus i Serken AS</Header>

        <Paragraph></Paragraph>
        <Paragraph>Bottemsvegen 116</Paragraph>
        <Paragraph>2665 Lesja</Paragraph>
        <Paragraph>Orgnr. 921 636 024</Paragraph>
        <Paragraph>post@susiserken.no</Paragraph>
      </FooterGroup>
    </FooterRow>
  </StyledFooter>
);

export default Footer;
